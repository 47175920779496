
import CartController from '~/components/shop/CartController.vue'
import Topbar from '~/components/Topbar.vue'
import SiteHeader from '~/components/SiteHeader.vue'
import SiteFooter from '~/components/SiteFooter.vue'
import SiteCopyrightFooter from '~/components/SiteCopyrightFooter.vue'
import TemporaryPopup from '~/components/TemporaryPopup.vue'
function getCookie(name) {
  const value = '; ' + document.cookie
  const parts = value.split('; ' + name + '=')
  if (parts.length === 2)
    return parts
      .pop()
      .split(';')
      .shift()
}
export default {
  data() {
    return {
      isApp: false,
    }
  },
  components: { Topbar, SiteHeader, SiteFooter, CartController, TemporaryPopup, SiteCopyrightFooter },
  computed:{
    page(){
      return this.$store.getters.activePage
    },
    isProductPage(){
      return this.page && this.page.layout && this.page.layout.includes('product')
    },
    isLanding(){
      return this.page && this.page.layout && this.page.layout.includes('landing')
    },
    hideNewsletter(){
      const noNewsletterRoutes = ['effective-methods-to-reduce-indoor-pollution','metodi-efficaci-per-ridurre-inquinamento-indoor','air-purifier-improves-health','purificatore-aria-migliora-salute','choose-best-purifier-complete-guide','scegliere-migliore-purificatore-guida-completa','12-benefici-purificatore-aria','12-benefits-air-purifier']
      if (this.$route.params?.uid) {
        return noNewsletterRoutes.includes(this.$route.params.uid)
      } else return false
    }
  },
  beforeMount() {
    const lang = getCookie('vitesy_i18n_redirected') || navigator.language.substr(0, 2)
    this.$router.push(this.switchLocalePath(lang))
  },
  // mounted(){
  //   const layoutMeta = this.$root.$meta().refresh().metaInfo.meta.find(m => m.hid === 'layout')
  //   this.isLanding = layoutMeta && layoutMeta.content === 'landing'
  //   this.isProductPage = layoutMeta && layoutMeta.content === 'product'
  //   },
  created() {
    this.$route.query.app ? this.isApp = true : this.isApp = false
  }
}
