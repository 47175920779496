
import FaqSearch from '~/components/faq/FaqSearch.vue'
export default {
  name: 'FaqSidebar',
  // props: ['product', 'section', 'faqId'],
  data() {
    return {
      faqs: [],
      sectionData: false,
      productData: false
    }
  },
  components: { FaqSearch },
  computed: {
    product() {
      return this.$route.params.product
    },
    section() {
      return this.$route.params.section
    },
    faqId() {
      return this.$route.params.uid
    }
  },
  watch: {
    async product(){
      await this.loadSidebarData()
    },
    async section(){
      await this.loadSidebarData()
    },
    async faqId(){
      await this.loadSidebarData()
    }
  },
  async mounted() {
    await this.loadSidebarData()
  },
  methods: {
    async loadSidebarData(){
      this.productData = this.product
      if (this.section) {
        this.sectionData = await this.getFaqSection()
        this.productData = this.sectionData.data.product
      }
      if (this.faqId) {
        this.faqs = await this.getFaqBySection()
      }
    },
    async getFaqSection() {
      const lang = this.$i18n.locales.filter((i) =>
        i.iso.includes(this.$i18n.locale)
      )[0].iso
      try {
        const section = await this.$prismic.api.getByUID('faq_section', this.section, {lang: lang})
        return section
      } catch (e) {
        return this.section
      }
    },
    async getFaqBySection() {
      const lang = this.$i18n.locales.filter((i) =>
        i.iso.includes(this.$i18n.locale)
      )[0].iso
      try {
        const faq = await this.$prismic.api.query([
          this.$prismic.predicates.at('document.type', 'faq'),
          this.$prismic.predicates.at('my.faq.section', this.sectionData.id),
        ], {orderings : '[my.faq.question]', lang})
        return faq.results
      } catch (e) {
        return []
      }
    },
  },
}
