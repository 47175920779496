
import CartController from '~/components/shop/CartController.vue'
export default {
  name: 'SiteHeader',
  data() {
    return {
      navShow: false,
      subnavShow: false
    }
  },
  components: { 
    CartController
  },
  computed: {
    routes() {
      return this.$t('menu')
    }
  },
  methods: {
    toggleMenu() {
      this.navShow = !this.navShow
      this.subnavShow = false
    },
    toggleSubMenu(routeId) {
      this.subnavShow === routeId
        ? (this.subnavShow = false)
        : (this.subnavShow = routeId)
    }
  }
}
