
export default {
  name: 'Countdown',
  props: ['date','layout', 'format'],
  data () {
    return {
      deadline: this.date.getTime(),
      countdown: false,
      hideCountdown: false
    }
  },
  created () {
    const _self = this
    setInterval(function () {
      let now = new Date().getTime()
      let distance = _self.deadline - now

      let days = Math.floor(distance / (1000 * 60 * 60 * 24))
      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      let seconds = Math.floor((distance % (1000 * 60)) / 1000)
      if (_self.$i18n.locale === 'it') {
        _self.countdown =
          days + 'g ' + hours + 'o ' + minutes + 'm ' + seconds + 's '
      } else if (_self.$i18n.locale === 'de') {
        _self.countdown =
          days + 't ' + hours + 's ' + minutes + 'm ' + seconds + 's '
      } else {
        _self.countdown =
          days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's '
      }
      if (_self.format === 'hours') {
        const timeDifference = Math.abs(distance);
        const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60))
        _self.countdown = hoursDifference + 1
      }

      if (distance < 0) {
        clearInterval()
        _self.hideCountdown = true
      }
    }, 1000)
  },
}
