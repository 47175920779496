
import Topbar from '~/components/Topbar.vue'
import SiteHeader from '~/components/SiteHeader.vue'
import SiteFooter from '~/components/SiteFooter.vue'
import FaqSidebar from '~/components/faq/FaqSidebar.vue'
function getCookie(name) {
  const value = '; ' + document.cookie
  const parts = value.split('; ' + name + '=')
  if (parts.length === 2) return parts.pop().split(';').shift()
}
export default {
  data() {
    return {
      isApp: false,
      isReaderMode: false,
      isDarkMode: false
    }
  },
  components: { Topbar, SiteHeader, SiteFooter, FaqSidebar },
  created() {
    this.$route.query.app ? this.isApp = true : this.isApp = false
    this.$route.query.reader && this.$route.query.reader === 'true' ? this.isReaderMode = true : this.isReaderMode = false
    this.$route.query.theme === 'dark' ? this.isDarkMode = true : this.isDarkMode = false
  },
  beforeMount() {
    const lang = getCookie('vitesy_i18n_redirected') || navigator.language.substr(0, 2)
    this.$router.push(this.switchLocalePath(lang))

    if(this.isReaderMode) {
      const styleElement = document.createElement("style");
      styleElement.innerHTML = `.cky-consent-container { display: none; }`;
      document.head.appendChild(styleElement);
    }
  },
}
