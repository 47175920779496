import { en, it, de } from '@braid/vue-formulate-i18n'

export default {
  plugins: [en, it, de],
  classes: {
    wrapper: '',
    outer: 'form-group',
    element: '',
    label: 'form-label',
    input: 'form-control',
    error: 'form-errors__error',
    errors: 'form-errors text-danger',
    help: '',
    labelHasValue: 'form-label--has-value',
    labelIsValid: 'form-label--valid',
    labelHasErrors: 'form-label--error',
    inputHasValue: 'form-control--has-value',
    inputIsValid: 'form-control--valid',
    inputHasErrors: 'form-control--error'
  }
}