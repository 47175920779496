
export default {
  name: 'LangSwitcher',
  props: ['theme'],
  data() {
    return {
      show: false
    }
  },
  computed: {
    availableLocales() {
      // return this.mini ? this.$i18n.locales : this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale)
      return this.$i18n.locales
    },
    currentLocale(){
      return this.$i18n.locales.find( l => l.code === this.$i18n.locale)
    },
    isBlogPage(){
      return this.$route.path.includes('blog')
    },
    isDark(){
      return this.theme === 'dark'
    }
  }
}
