import { render, staticRenderFns } from "./FaqSidebar.vue?vue&type=template&id=8b009a44&scoped=true"
import script from "./FaqSidebar.vue?vue&type=script&lang=js"
export * from "./FaqSidebar.vue?vue&type=script&lang=js"
import style0 from "./FaqSidebar.vue?vue&type=style&index=0&id=8b009a44&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b009a44",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FaqSearch: require('/opt/build/repo/components/faq/FaqSearch.vue').default,NuxtLinkCustom: require('/opt/build/repo/components/NuxtLinkCustom.vue').default})
