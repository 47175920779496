
import CountrySelector from '~/components/shop/CountrySelector.vue'
import Countdown from '~/components/Countdown.vue';

export default {
  name: 'Topbar',
  components: { CountrySelector, Countdown },
  props: ['modalToTriggerId'],
  computed: {
    page(){
      return this.$store.getters.activePage
    },
    isProductPage(){
      return this.page && this.page.layout === 'product'
    },
    isLanding(){
      return this.page && this.page.layout === 'landing'
    },
    currency(){
      const currencyCode = this.$store.getters.currency || 'EUR'
      switch(currencyCode) {
        case 'EUR' : return '€'
        case 'USD' : return '$'
        case 'GBP' : return '£'
        default: return '€'
      }
    }
  }
}
