import Vue from 'vue'
// import Imagee from '~/components/Imagee.vue'
// import Hero from '~/components/Hero.vue'
// import PatternCircle from '~/components/PatternCircle.vue'
// import FullCircle from '~/components/FullCircle.vue'
// Vue.component('Imagee', Imagee)
// Vue.component('Hero', Hero)
// Vue.component('PatternCircle', PatternCircle)
// Vue.component('FullCircle', FullCircle)

// import HeroSlider from '~/components/HeroSlider.vue'
// import Popup from '~/components/Popup.vue'
// // import PreorderPopup from '~/components/PreorderPopup.vue'

// Vue.component('HeroSlider', HeroSlider)
// Vue.component('Popup', Popup)
// // Vue.component('PreorderPopup', PreorderPopup)

Vue.filter('slug', function (value) {
  value = value?.replace(/^\s+|\s+$/g, '') // trim
  value = value?.toLowerCase()

  // remove accents, swap ñ for n, etc
  const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;'
  const to = 'aaaaaeeeeeiiiiooooouuuunc------'
  for (let i = 0, l = from.length; i < l; i++) {
    value = value.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  value = value
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes

  return value
})

const slug = function (str) {
  str = str?.replace(/^\s+|\s+$/g, '') // trim
  str = str?.toLowerCase()

  // remove accents, swap ñ for n, etc
  const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;'
  const to = 'aaaaaeeeeeiiiiooooouuuunc------'
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes

  return str
}

Vue.filter('text', function (value) {
  if (value) {
    return value.split("-").join(" ")
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  } else {
    return value
  }
})

Vue.filter('price', function (value, currencyCode) {
    if (value && currencyCode) {
      return value.toLocaleString('en-US', { 
        style: 'currency', 
        currency: currencyCode
        // minimumFractionDigits: 2,
        // maximumFractionDigits: 2 
      })
    } else {
      return ''
    }
})

Vue.filter('priceInt', function (value, currencyCode) {
    if (value && currencyCode) {
      return value.toLocaleString('en-US', { 
        style: 'currency', 
        currency: currencyCode,
        // minimumFractionDigits: 2,
        maximumFractionDigits: 0 
      })
    } else {
      return ''
    }
})
