
import countries from '~/assets/data/countries_all'
export default {
  name: 'NewsletterForm',
  props: ['channel', 'campaign'],
  data() {
    return {
      formData: {},
      submitSuccess: false,
      submitError: false,
      countries,
      formCountries: []
    }
  },
  computed: {
    currentLocale() {
      return this.$i18n.locale
    },
    storeCountry() {
      return this.$store.getters.country
    }
  },
  async mounted(){
    this.initForm()
    this.formCountries = this.countries.map(country => {
      return { value: country.code, label: country.name[this.currentLocale] }
    })
  },
  methods: {
    initForm(){
      this.formData = { 
        campaign: this.campaign, 
        channel_group: 'Services',
        channel: this.channel,
        policies: { privacy: true, newsletter: true, direct_marketing: true },
        country: this.storeCountry ? this.storeCountry.code : null
      }
    },
    async submitForm() {
      this.submitSuccess = false
      this.submitError = false
      const data = {...this.formData, policies: Array.isArray(this.formData.policies) ? this.formData.policies[0] : this.formData.policies}
      const response = await this.sendToGateway(data)
      if (!response || response?.accountId?.error || response?.contactId?.error) {
        this.submitError = true
      } else {
        this.submitSuccess = true
        this.trackFormSubmission()
        this.initForm()
        this.$formulate.resetValidation('newsletter-form')
      }
    },
    async sendToGateway (data) {
      try {
        const response = await fetch(
          'https://gwapi.vitesy.com/api/crm/customer/',
          // 'http://localhost:8081/api/crm/customer/',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
          }
        )
        return await response.json()
      } catch(err){
        return false
      }
    },
    trackFormSubmission(){
      const eventAttributes = {
        form_name: `Newsletter Form`,
        form_category: 'Subscription Form',
        form_url: this.$route.fullPath,
        user_data: {
          user_email: this.formData.email,
          user_country: this.formData.country
        }
      }
      this.$gtm.push({ event: 'generate_lead', ...eventAttributes })
    }
  }
}
