
import countries from '~/assets/data/countries_ecommerce'
export default {
  name: 'CountrySelector',
  data() {
    return {
      confirmed: true,
      country: null,
      countries
    }  
  },
  computed: {
    storeCountry() {
      return this.$store.getters.country
    }
  },
  mounted(){
    this.country = this.$store.getters.country
    this.country ? this.confirmed = true : this.confirmed = false
  },
  watch: {
    storeCountry(country){
      this.country = country
      this.country ? this.confirmed = true : this.confirmed = false
    }
  },
  methods: {
    async setCountry(country){
      await this.$store.dispatch('setCountry', country)
      this.confirmed = true
    }
  }
}
