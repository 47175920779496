
export default {
  name: 'Social',
  data() {
    return {
      links: [
        {
          url: "https://www.facebook.com/vitesy/",
          iconClass: "facebook",
          title: "Facebook"
        },
        {
          url: "https://www.instagram.com/vitesy/",
          iconClass: "instagram",
          title: "Instagram"
        },
        {
          url: "https://twitter.com/vitesy_tw/",
          iconClass: "twitter",
          title: "Twitter"
        },
        {
          url: "https://www.linkedin.com/company/vitesy/",
          iconClass: "linkedin",
          title: "Linkedin"
        },
        {
          url: "https://www.youtube.com/@vitesy",
          iconClass: "youtube",
          title: "YouTube"
        },
        {
          url: "https://tiktok.com/@vitesy",
          iconClass: "tiktok",
          title: "TikTok"
        }
      ]
    }
  }
}
