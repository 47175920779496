export const state = () => ({
  footer: {}
})

export const mutations = {
  setFooter(state, data) {
    state.footer[data.lang] = data.footer
  }
}

export const actions = {
  async getFooterData({ commit, state }, lang) {
    if (!state.footer[lang]) {
      const footer = { ...await this.app.$prismic.api.getSingle('footer', { lang }), navs: [] }
      // if (footer.data) {
      //   await footer.data.body.reduce(async (f, slice, index) => {
      //     if (slice.slice_type === 'content_type_list') {
      //       await slice.items.reduce(async (nav, item, index) => {
      //         const itemData = await this.app.$prismic.api.getByUID(item.item.type, item.item.uid, { lang })
      //         footer.navs[index] = { title: itemData.data.navigation_title, items: itemData.data.body, id: item.item.uid }
      //       }, Promise.resolve())
      //     }
      //   }, Promise.resolve())
      // }
      commit('setFooter', { lang, footer })
    } else {
      // commit('setFooter', { lang, footer })
    }
    return state.footer[lang]
  }
}
export const getters = {
  footer: state => {
    return state.footer
  }
}
