
export default {
  name: 'TemporaryPopup',
  props: ['id', 'delay', 'image', 'exitIntent'],
  data() {
    return {
      show: false,
      dismissed: false
    }
  },
  mounted() {
    if (this.delay) {
      setTimeout(() => {
        if (!this.getCookie(`${this.id}-popup`).length) {
          this.show = true
        }
      }, this.delay)
    }

    if (this.exitIntent) {
      document.addEventListener('mouseleave', this.handleExitIntent);
    }
  },
  beforeDestroy() {
    if (this.exitIntent) {
      document.removeEventListener('mouseleave', this.handleExitIntent);
    }
  },
  methods: {
    openModal() {
      this.show = true
      this.dismissed = false
    },
    closeModal() {
      this.dismissed = true
      this.setCookie(`${this.id}-popup`, 'opened', 7)
      setTimeout(() => {
        this.show = false
      }, 500)
    },
    setCookie(cookieName, cookieValue, expirationDays) {
      const d = new Date()
      d.setTime(d.getTime() + expirationDays * 24 * 60 * 60 * 1000)
      const expires = 'expires=' + d.toUTCString()
      document.cookie =
        cookieName + '=' + cookieValue + ';' + expires + ';path=/'
    },
    getCookie(cookieName) {
      const name = cookieName + '='
      const decodedCookie = decodeURIComponent(document.cookie)
      const ca = decodedCookie.split(';')
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ''
    },
    handleExitIntent(event) {
      if (event.clientY < 50) {
        if (!this.getCookie(`${this.id}-popup`).length) {
          this.openModal()
        }
      }
    },
  },
}
