
export default {
  props: {
    to: {
      type: [String, Object],
      required: true
    }
  },
  computed: {
    toWithUtm() {
      const utmParams = this.$store.state.utmParams;
      let path = this.to;

      if (typeof this.to === 'object') {
        path = { ...this.to, query: { ...this.to.query, ...utmParams } };
      } else {
        path = { path: this.to, query: { ...utmParams } };
      }

      return path;
    }
  }
};
