
import NewsletterSection from './NewsletterSection.vue'
import FooterNavigation from './FooterNavigation.vue'
// import SalesforceChat from './SalesforceChat.vue'
export default {
  name: 'SiteFooter',
  components: {
    NewsletterSection,
    FooterNavigation,
    // SalesforceChat
  },
  props: ['hideNewsletter'],
  data() {
    return {
      footer: false,
      backToTop: false
    }
  },
  async fetch(){
    const lang = this.$i18n.locales.filter(i => i.iso.includes(this.$i18n.locale))[0].iso
    const footer = await this.$prismic.api.getSingle('footer', { lang })
    this.footer = footer
  },
  computed: {
    lang() {
      return this.$i18n.locales.find(locale => locale.code === this.$i18n.locale).iso
    },
    copyright(){
      return this.$prismic.asText(this.footer?.data?.copyright)
    }
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll, { passive: true })
    
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods:{
    handleScroll(event) {
      window.scrollY > 800 ? (this.backToTop = true) : (this.backToTop = false)
    },
  }
}
