import { render, staticRenderFns } from "./Topbar.vue?vue&type=template&id=11811057&scoped=true&lang=pug"
import script from "./Topbar.vue?vue&type=script&lang=js"
export * from "./Topbar.vue?vue&type=script&lang=js"
import style0 from "./Topbar.vue?vue&type=style&index=0&id=11811057&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11811057",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Countdown: require('/opt/build/repo/components/Countdown.vue').default,LangSwitcher: require('/opt/build/repo/components/LangSwitcher.vue').default})
