import { render, staticRenderFns } from "./foodprintcalculator.vue?vue&type=template&id=47e1c4f6&scoped=true&lang=pug"
var script = {}
import style0 from "./foodprintcalculator.vue?vue&type=style&index=0&id=47e1c4f6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47e1c4f6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Social: require('/opt/build/repo/components/Social.vue').default})
