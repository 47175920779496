
  export default {
    computed: {
      show() {
        return this.$store.state.toast.show;
      },
      message() {
        return this.$store.state.toast.message;
      }
    },
    methods: {
      hideToast() {
        this.$store.dispatch('hideToast')
      }
    }
  };
  