
export default {
  name: 'JLink',
  props: ['link','linkClasses'],
  data(){
    return {
      dynamicRoutesTypes: ['page','product_page','faq_page','blog']
    }
  },
  computed: {
    pageName(){
      return this.link?.uid?.charAt(0)?.toUpperCase() + this.link?.uid?.slice(1)
    }
  }
}
